<template>
    <div>
        <el-tree :data="data" :node-class="getNodeClass" :props="defaultProps" :highlight-current="true" @node-click="handleNodeClick"></el-tree>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data: [{
                    label: '一级 1',
                    children: [{
                        label: '二级 1-1',
                        children: [{
                            label: '三级 1-1-1'
                        }]
                    }]
                }, {
                    label: '一级 2',
                    children: [{
                        label: '二级 2-1',
                        children: [{
                        label: '三级 2-1-1'
                        }]
                    }, {
                        label: '二级 2-2',
                        children: [{
                        label: '三级 2-2-1'
                        }]
                    }]
                }, {
                    label: '一级 3',
                    children: [{
                        label: '二级 3-1',
                        children: [{
                        label: '三级 3-1-1'
                        }]
                    }, {
                        label: '二级 3-2',
                        children: [{
                        label: '三级 3-2-1'
                        }]
                    }]
                }, {
                    label: '一级'
                }],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                }
            };
        },
        methods: {
            handleNodeClick(data) {
                console.log(data);
            },
            // 返回每个节点的类名
            getNodeClass(node) {
                return node.classNode || ''; // 如果节点有classNode字段，则返回该字段的类名
            },
            // 递归函数，遍历树形结构并在最后一级子节点添加 classNode 字段
            addClassNode(nodes) {
                return nodes.map(node => {
                    // 如果没有 children，则为当前节点添加 classNode: 'noChild'
                    if (!node.children || node.children.length === 0) {
                        node.classNode = 'noChild';
                    } else {
                    // 如果有子节点，继续递归
                        node.children = this.addClassNode(node.children);
                    }
                    return node;
                });
            },
        },
        created() {
            // 在 created 生命周期钩子中调用递归函数，并更新 data
            this.data = this.addClassNode(this.data);
        }
    };

</script>

<style lang="less" scoped>
    // .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
        
    // }
</style>